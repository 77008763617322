import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgChartsModule } from 'ng2-charts';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '@shared';
import { MaterialModule } from '@app/material.module';
import { GeneAnalyticsRoutingModule } from './gene-analytics-routing.module';
import { GeneAnalyticsComponent } from './gene-analytics.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    FlexLayoutModule,
    MaterialModule,
    NgChartsModule,
    GeneAnalyticsRoutingModule,
    NgbModule,
  ],
  declarations: [GeneAnalyticsComponent],
})
export class GeneAnalyticsModule {}
