import { Title } from '@angular/platform-browser';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { DOCUMENT } from '@angular/common';
import { Auth } from 'aws-amplify';

import { CredentialsService } from '@app/auth';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() sidenav!: MatSidenav;
  user: any;
  access_token: any;

  constructor(
    private router: Router,
    private titleService: Title,
    private credentialsService: CredentialsService    
  ) {}

  ngOnInit() {
  }

  login() {
    
  }

  logout() {
    Auth.signOut();
    this.router.navigate(['/home'], { replaceUrl: true });
  }

  get username(): string | null {
    const credentials = this.credentialsService.credentials;
    return credentials ? credentials.email : null;
  }

  get authenticated(): boolean {
    const credentials = this.credentialsService.credentials;
    return !!credentials;
  }


  get title(): string {
    return this.titleService.getTitle();
  }
}
