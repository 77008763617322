import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { BackendService } from '@shared/backend.service';
import { CredentialsService } from '@app/auth';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(
    private backendService: BackendService,
    private credentialsService: CredentialsService    
    ) {}

  refreshText = '';
  isLoading = false;

  ngOnInit() {

    console.log('foo')
  }

  get username(): string | null {
    const credentials = this.credentialsService.credentials;
    return credentials ? credentials.email : null;
  }

  get authenticated(): boolean {
    const credentials = this.credentialsService.credentials;
    return !!credentials;
  }

  get admin(): boolean {
    const credentials = this.credentialsService.credentials;
    return credentials? credentials.groups.includes('admin') : false;
  }

  public refreshData() {
    this.refreshText = 'Refreshing data';
    this.isLoading = true;
    setTimeout(() => {
      this.backendService.refreshData().subscribe((response: any) => {
        if (response) {
          this.refreshText = response.message;;
        }
        this.isLoading = false;
      });
    })  

  }

}


