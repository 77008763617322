<mat-toolbar class="mat-elevation-z6 navbar" color="primary">
  <div fxHide.gt-sm fxLayoutAlign="start center">
    <button class="menu-button" mat-icon-button (click)="sidenav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <span class="title">{{ title }}</span>
  </div>
  <div fxHide.lt-md>
    <a class="brand" href="https://www.clareo-genomics.com" translate>APP_NAME</a>
    <button mat-button routerLink="/home" routerLinkActive="active">
      <span translate>Home</span>
    </button>
    <button mat-button routerLink="/about" routerLinkActive="active">
      <span translate>About</span>
    </button>
    <button *ngIf="authenticated" mat-button routerLink="/download" routerLinkActive="active">
      <span translate>Samples</span>
    </button>
    <button *ngIf="authenticated" mat-button [matMenuTriggerFor]="analyticsMenu">
      <span translate>Reports</span>
    </button>
    <mat-menu #analyticsMenu="matMenu">
      <mat-list>
        <mat-list-item>
          <button *ngIf="authenticated" mat-button routerLink="/analytics" routerLinkActive="active">Project Summary</button>
          </mat-list-item>
          <mat-list-item>
          <button *ngIf="authenticated" mat-button routerLink="/gene-analytics" routerLinkActive="active">Single Gene</button>
        </mat-list-item>
    </mat-list>
    </mat-menu>
    </div>
  <span fxFlex></span>
  <button mat-icon-button [matMenuTriggerFor]="userMenu">
    <mat-icon>person</mat-icon>
  </button>
  <mat-menu #userMenu="matMenu">
    <mat-list>
      <mat-list-item *ngIf="authenticated" >
        <span translate>Logged in as</span>&nbsp;{{ username }}<b></b>
      </mat-list-item>
      <mat-list-item >
        <button *ngIf="!authenticated" mat-menu-item routerLink="/login" translate>Login</button>
      </mat-list-item>
      <mat-divider></mat-divider>
    </mat-list>
    <button *ngIf="authenticated" mat-menu-item (click)="logout()" translate>Logout</button>
  </mat-menu>
</mat-toolbar>
