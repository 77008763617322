import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy, ElementRef, inject, TemplateRef } from '@angular/core';
import { BackendService } from '@shared/backend.service';
import { LoaderComponent } from '@shared/loader/loader.component';
import { DataTableDirective } from 'angular-datatables';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { environment } from '@env/environment';
import { Subject } from 'rxjs';
import { faFilePdf, faFileExcel, faFileText, faFileWord, faFileImage, faFileZipper } from '@fortawesome/free-regular-svg-icons';
import { faDesktop, faFileCircleQuestion, faCopy } from '@fortawesome/free-solid-svg-icons';
import { icon } from '@fortawesome/fontawesome-svg-core';
import {  NgbModal } from '@ng-bootstrap/ng-bootstrap';


interface ProjectDetails {
  project_data: {};
  project_id: string;
  sample_cols: any[];
  samples: any[];
  files: any[];
}

interface SampleDetails {
  sample_id: string
}


// Icons used in child rows of the table
const iconLookup = {
  '.xlsx': faFileExcel,
  '.xls': faFileExcel,
  '.pdf': faFilePdf,
  '.txt': faFileText,
  '.docx': faFileWord,
  '.doc': faFileWord,
  '.html': faDesktop,
  '.png': faFileImage,
  '.fastq': faFileText,
  '.zip': faFileZipper,
  '.gz': faFileZipper,
}

@Component({
  selector: 'app-project-files',
  templateUrl: './project-files.component.html',
  styleUrls: ['./project-files.component.scss'],
})
export class ProjectFilesComponent implements AfterViewInit, OnDestroy, OnInit {
    @ViewChild('dtTableElement') dtTableElement!: ElementRef;
    @ViewChild('content') content!: TemplateRef<any>;
    @ViewChild(DataTableDirective, {static: false})
    dtElement!: DataTableDirective;  
    dtOptions: DataTables.Settings = {};  
    dtTrigger: Subject<ADTSettings> = new Subject();
    projectFiles: any[] = [];

    private modalService = inject(NgbModal);
    projectDetails: ProjectDetails[] = [];
    selectedProject: string = '';
    selectedProjectDetails!: ProjectDetails;  
    faCopy = faCopy;
    isLoading = false;
  
    constructor(
      private backendService: BackendService,
    ) {}

    ngOnInit(): void {
      this.dtOptions = {
        lengthMenu: [[100, 250, 500, -1], [100, 250, 500, "All"]],
        data: [{'id': 1},],
        columns: [{
          title: 'ID',
          data: 'id'
        }],
        
        rowCallback: (row: Node, data: SampleDetails | Object, index: number) => {
          const self = this;
          // Unbind first in order to avoid any duplicate handler
          // (see https://github.com/l-lin/angular-datatables/issues/87)
          // Note: In newer jQuery v3 versions, `unbind` and `bind` are 
          // deprecated in favor of `off` and `on`
          $('td:first', row).off('click');
          $('td:first', row).on('click', () => {
            self.rowFirstCellClickHandler(row, data);
          });
          return row;
        }
        
      }
    }
  
    ngAfterViewInit(): void {

      setTimeout(() => {
        this.dtTrigger.next(this.dtOptions);
        this.isLoading = true;
        this.backendService.getUserProjectDetails().subscribe((response: any) => {
          if (response.project_data) {
            this.isLoading = false;
  
            this.projectDetails = response.project_data;
            if (this.projectDetails && this.projectDetails.length > 0) {
              this.selectProject(this.projectDetails[0].project_id);
            } else {
              this.dtOptions.columns = [{'data': 'id', 'title': 'ID'}, {'data': 'sample_name', 'title': 'Sample Name'}];
              this.dtOptions.data = [];
              this.rerender(this.dtOptions)
              this.selectedProject = '';                    }
          }
        });
      })  
    }
  
    ngOnDestroy(): void {
      // Do not forget to unsubscribe the event
      this.dtTrigger.unsubscribe();
    }
  
    getSelectedProject(event: any) {
      this.selectProject(event.target.value);
    }

    copyLinkToClipboard(path: string) {
      this.backendService.getS3Link(path).subscribe((response: any) => {
        console.log(response);
        if (response.status === 200) {
          navigator.clipboard.writeText(response.url);

          this.modalService.open(this.content)
        }
      });
    }

    selectProject(project_id: string) {
      console.log("selecting project");
      let found = false;
      
      this.projectDetails.forEach((project) => {
        if (project.project_id === project_id) {
          found = true;
          let cols = project.sample_cols;
          if (!cols[0].defaultContent) {
            cols.unshift({defaultContent: '<span class="material-icons" style="font-size: 15px;">add_circle_outline</span>'});
          }

          this.projectFiles = []
          project.files.forEach((file: any) => {
            let file_icon = faFileCircleQuestion;
            if (file.file_ext in iconLookup) {
              file_icon = iconLookup[file.file_ext] 
            }
            this.projectFiles.push({
              'file_name': file.filename, 
              'file_red': environment.serverUrl + file.url, 
              'file_url': file.url.replace('s3_redirect/', ''),
              'file_icon': file_icon});    
          });

          this.dtOptions.columns = cols;
          this.dtOptions.data = Object.values(project.samples);
          this.selectedProjectDetails = project;
        }
      })

      
      this.rerender(this.dtOptions)
      this.selectedProject = project_id;
    }

    rowFirstCellClickHandler(tr: any, data: any) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        let row = dtInstance.row(tr);

          if (row.child.isShown()) {
            // This row is already open - close it
            row.child.hide();
          }
          else {
            this.isLoading = true;
            this.backendService.getSampleFiles(this.selectedProjectDetails.project_id, data.sample_id).subscribe((response: any) => {
              if (response.file_list) {
                var child_text = '';

                if (response.file_list['files'].length > 0) {
                  child_text += '<h6>Entire sample</h6>'
                  child_text += this.format(response.file_list['files'])
                }
                
                for (var locus in response.file_list['loci']) {
                  child_text += '<h6>' + locus + '</h6>'
                  child_text += this.format(response.file_list['loci'][locus]);
                }

                row.child(child_text).show();
              }
              this.isLoading = false;
            });
          }    
        });
    }

    format(d: any) {
      let file_descs = `<div class="row"><ul class="list-inline">`;
      d.forEach((file_row: any) => {
        let file_icon = faFileCircleQuestion;
        if (file_row[1] in iconLookup) {
          file_icon = iconLookup[file_row[1]] 
        }

        file_descs += `<li class="list-inline-item"><a href="${environment.serverUrl + file_row[2]}">${icon(file_icon).html.join('')}</a><span>&nbsp;&nbsp;</span>${file_row[0]}</li>`;
      });
      file_descs += '</ul></div>'
      return(file_descs);
  }
  
    // https://stackoverflow.com/questions/51324249/how-can-i-update-the-number-of-columns-in-angular-datatables-with-server-side-re

    rerender(newSettings?: DataTables.Settings) {
      try {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              if (newSettings) {
                  // FIX To ensure that the DT doesn't break when we don't get columns
                  if (newSettings.columns && newSettings.columns.length > 1) {
                      dtInstance.destroy();
                      this.dtOptions = newSettings;
                      this.displayTable(this.dtTableElement);
                  }
              }          
        });          
      } catch (error) {
          console.log(`DT Rerender Exception: ${error}`);
      }
    }
    
    private displayTable(renderIn: ElementRef): void {
      this.dtElement.dtInstance = new Promise((resolve, reject) => {
          Promise.resolve(this.dtOptions).then(dtOptions => {
              // Using setTimeout as a "hack" to be "part" of NgZone
              setTimeout(() => {
                  $(renderIn.nativeElement).empty();
                  var dt = $(renderIn.nativeElement).DataTable(dtOptions);
                  // this.dtTrigger.next();
                  resolve(dt);
              });
          }).catch(error => reject(error));
      });
    }    
  }
  