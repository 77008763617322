<div class="container" style="padding-left: 15px; padding-right: 15px; padding-top: 25px">
  <app-loader [isLoading]="isLoading"></app-loader>

  <h2>Project Summary</h2>
  <div *ngIf="projectDetails">  
    <div *ngIf="projectDetails.length > 1">
      <div class="row p-3">
        <div class="col-3">
          <label>Project</label>
          <select class="form-select" (change)="getSelectedProject($event)">
            <option *ngFor="let project of projectDetails" [value]="project.project_id">
              {{project.project_data['project_name']}}
            </option>
          </select>
        </div>  
      </div>
    </div>
  </div>
  
  <div *ngIf="projectViewData"> 
    <div class="row p-3 justify-content-md-center">
      <h3>Subject and sample count</h3>

      <div class="col-md-auto"  style="width:600px">
        <canvas baseChart
            [data]="projectViewData.subj_count_chart"
            [options]="barChartOptions"
            [plugins]="barChartPlugins"
            [legend]="true"
            [type]="'bar'">
        </canvas>
      </div>
      <h3>Allele count</h3>

      <div class="col-md-auto"  style="width:600px">
        <canvas baseChart
            [data]="projectViewData.allele_count_chart"
            [options]="barChartOptions"
            [plugins]="barChartPlugins"
            [legend]="true"
            [type]="'bar'">
        </canvas>
      </div>
      <h3>Novel Allele Support</h3>

      <div class="col-md-auto"  style="width:600px">
        <div *ngFor="let loc of projectViewData.allele_count_chart.labels">
          <h3>{{loc}}</h3>

        <canvas baseChart
            [data]="projectViewData.allele_support_charts[loc]"
            [options]="barChartNovelSupportOptions"
            [plugins]="barChartPlugins"
            [legend]="false"
            [type]="'bar'">
        </canvas>
        </div>
      </div>
    </div>
  </div>
</div>