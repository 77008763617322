import { NgModule } from '@angular/core';
import { ProjectFilesComponent } from './project-files.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms'; 

import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DataTablesModule } from 'angular-datatables';

import { SharedModule } from '@shared';
import { MaterialModule } from '@app/material.module';
import { MatIconModule } from '@angular/material/icon';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ProjectFilesRoutingModule } from './project-files-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { MatButtonModule } from '@angular/material/button'; 
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    FlexLayoutModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    FontAwesomeModule,
    MaterialModule,
    MatIconModule,
    MatButtonModule,
    DataTablesModule,
    ProjectFilesRoutingModule,
    NgbModule,
  ],
  declarations: [ProjectFilesComponent],
  bootstrap: [ProjectFilesComponent],
})
export class ProjectFilesModule {}
