<div class="container">
  <mat-card>
    <mat-card-title translate>
      <div *ngIf="!admin">
        Welcome
      </div>
      <div *ngIf="admin">
        Welcome, admin
      </div>
      </mat-card-title>
    <p>
      <img class="logo" src="assets/light-bulb.svg" alt="Clareo logo" />
    </p>
    <div *ngIf="authenticated">
      <mat-card-content>
        <mat-card-subtitle>
          <span>You are logged in as {{username}}</span>
        </mat-card-subtitle>
      </mat-card-content>
    </div>
    <div *ngIf="!authenticated">
      <mat-card-content>
        <mat-card-subtitle>
          <button type="button" class="btn btn-outline-primary btn-sm" routerLink="/login" translate>Login</button>
        </mat-card-subtitle>
      </mat-card-content>
    </div>
    <div *ngIf="admin">
      <mat-card-content>
        <mat-card-subtitle>
          <button type="button" class="btn btn-outline-primary btn-sm" (click)="refreshData()" translate>Refresh data</button>
        </mat-card-subtitle>
      </mat-card-content>
      <div *ngIf="refreshText">
        <mat-card-content>
          <mat-card-subtitle>
            <span>{{refreshText}}</span>
          </mat-card-subtitle>
        </mat-card-content>
      </div>
    </div>
      </mat-card>
</div>
