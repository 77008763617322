import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OnInit } from '@angular/core';
import { Hub, Auth } from 'aws-amplify';
import { Logger } from '../@shared/logger.service';

export interface Credentials {
  username: string;
  email: string;
  groups: string[];
}

const credentialsKey = 'credentials';

const logger = new Logger('My-Logger');

const logAuthEvents = (data: any) => {
    switch (data.payload.event) {
        case 'configured':
          logger.info('the Auth module is configured');
          break;
        case 'signIn':
          logger.info('user signed in');
          break;
        case 'signIn_failure':
          logger.error('user sign in failed');
          break;
        case 'signUp':
          logger.info('user signed up');
          break;
        case 'signUp_failure':
          logger.error('user sign up failed');
          break;
        case 'confirmSignUp':
          logger.info('user confirmation successful');
          break;
        case 'completeNewPassword_failure':
          logger.error('user did not complete new password flow');
          break;
        case 'autoSignIn':
          logger.info('auto sign in successful');
          break;
        case 'autoSignIn_failure':
          logger.error('auto sign in failed');
          break;
        case 'forgotPassword':
          logger.info('password recovery initiated');
          break;
        case 'forgotPassword_failure':
          logger.error('password recovery failed');
          break;
        case 'forgotPasswordSubmit':
          logger.info('password confirmation successful');
          break;
        case 'forgotPasswordSubmit_failure':
          logger.error('password confirmation failed');
          break;
        case 'verify':
          logger.info('TOTP token verification successful');
          break;
        case 'tokenRefresh':
          logger.info('token refresh succeeded');
          break;
        case 'tokenRefresh_failure':
          logger.error('token refresh failed');
          break;
        case 'cognitoHostedUI':
          logger.info('Cognito Hosted UI sign in successful');
          break;
        case 'cognitoHostedUI_failure':
          logger.error('Cognito Hosted UI sign in failed');
          break;
        case 'customOAuthState':
          logger.info('custom state returned from CognitoHosted UI');
          break;
        case 'customState_failure':
          logger.error('custom state failure');
          break;
        case 'parsingCallbackUrl':
          logger.info('Cognito Hosted UI OAuth url parsing initiated');
          break;
        case 'userDeleted':
          logger.info('user deletion successful');
          break;
        case 'updateUserAttributes':
          logger.info('user attributes update successful');
          break;
        case 'updateUserAttributes_failure':
          logger.info('user attributes update failed');
          break;
        case 'signOut':
          logger.info('user signed out');
          break;
    }
}

@Injectable({
  providedIn: 'root',
})
export class CredentialsService  {
  private _credentials: Credentials | null = null;

  constructor(private router: Router) {
    Auth.currentAuthenticatedUser().then(user => {
        console.log("currentAuthenticatedUser: " + user.username);
        this.setCredentials({
            username: user.username,
            email: user.attributes.email,
            groups: user.signInUserSession.accessToken.payload['cognito:groups']
        });
    }).catch(err => {
        console.log("error currentAuthenticatedUser: " + err);
        this.setCredentials();
    });
    Hub.listen('auth', this.listener);
  }


  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  get isAuthenticated(): boolean {
    return !!this._credentials;
  }

  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get credentials(): Credentials | null {
    return this._credentials;
  }

  /**
   * Sets the user credentials.
   * @param credentials The user credentials.
   */
  setCredentials(credentials?: Credentials) {
    if (credentials)
        console.log("setting credentials: " + credentials.username);
    else
        console.log("setting credentials: null");

    this._credentials = credentials || null;
  }

    listener = (data: any) => {
        logAuthEvents(data)
        switch(data.payload.event) {
            case 'signIn':
                console.log('user signed in');
                this.setCredentials({
                    username: data.payload.data.username,
                    email: data.payload.data.signInUserSession.idToken.payload.email,
                    groups: data.payload.data.signInUserSession.accessToken.payload['cognito:groups']
                });
                this.router.navigate(['/home']);
                break;
            case 'signOut':
                console.log('user signed out');
                this.setCredentials();
                this.router.navigate(['/home']);
                break;
        }      
    }
}