import { Component, OnInit, AfterViewInit, OnDestroy,  } from '@angular/core';
import { BackendService } from '@shared/backend.service';
import { ChartConfiguration } from 'chart.js';

interface ProjectDetails {
  project_data: {};
  project_id: string;
  sample_cols: any[];
  samples: any[];
}


@Component({
  selector: 'gene-analytics',
  templateUrl: './gene-analytics.component.html',
  styleUrls: ['./gene-analytics.component.scss'],
})

export class GeneAnalyticsComponent implements AfterViewInit {
  isLoading = false;
  projectDetails: ProjectDetails[] = [];
  public projectGeneData: any = null;
  selectedProject: string = '';
  selectedProjectDetails!: ProjectDetails; 
  selectedLocus: string = ''; 
  selectedGene: string = ''; 
  public projectLoci: string[] = [];
  public projectGenes: string[] = [];
  public barChartPlugins = [];
  public geneOccurrencePlot: any = null;
  public alleleOccurrencePlot: any = null;
  public alleleZygosityPlot: any = null;

  public barChartOptions: ChartConfiguration<'bar'>['options'] = {
    responsive: true,
    scales: {
      x: {
        ticks: { autoSkip: false }
      }
    }
  };

  constructor(
    private backendService: BackendService
  ) {}

  ngAfterViewInit(): void {
    this.isLoading = true;
    setTimeout(() => {
      this.backendService.getUserProjectDetails().subscribe((response: any) => {
        if (response.project_data) {
          this.projectDetails = response.project_data;
          if (this.projectDetails && this.projectDetails.length > 0) {
            this.selectProject(this.projectDetails[0].project_id);
          } 
        }
        this.isLoading = false;
      });
    })  
  }

  onSelectedProject(event: any) {

    this.selectProject(event.target.value);
  }

  selectProject(project_id: string) {

    this.projectGeneData = null;   
    this.selectedProject = project_id;

    this.projectDetails.forEach((project) => {
      if (project.project_id === project_id) {
        this.selectedProjectDetails = project;
      }
    })

    this.projectLoci = [];
    this.projectGenes = [];
    this.selectedGene = '';
    this.geneOccurrencePlot = null;
    this.alleleOccurrencePlot = null;
    this.alleleZygosityPlot = null;

    this.isLoading = true;
    this.backendService.getProjectGeneData(this.selectedProject).subscribe((response: any) => {
        if (response.status === 200) {
          this.projectGeneData = response;
          this.projectLoci = this.projectGeneData.loci;

          if (this.projectLoci.length > 0) {
            this.selectLocus(this.projectLoci[0]);
        }
      }
      this.isLoading = false;
    });
  }

  onSelectedLocus(event: any) {
    this.selectLocus(event.target.value);
  }

  selectLocus(locus: string) {
    this.selectedLocus = locus;
    this.projectGenes = Object.keys(this.projectGeneData.allele_occurrence_plots[this.selectedLocus]);

    if (this.projectGenes.length > 0) {
      this.selectGene(this.projectGenes[0]);
    } else {
    }
  }

  onSelectedGene(event: any) {
    this.selectGene(event.target.value);
  }

  selectGene(gene: string) {
    console.log('selecting gene ' + gene);
    this.selectedGene = gene;
    this.geneOccurrencePlot = this.projectGeneData.observed_gene_plots[this.selectedLocus][this.selectedGene];
    this.alleleOccurrencePlot = this.projectGeneData.allele_occurrence_plots[this.selectedLocus][this.selectedGene];
    this.alleleZygosityPlot = this.projectGeneData.zygosity_plots[this.selectedLocus][this.selectedGene];
  }
}
