import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { retryWithBackoff } from './retry_with_backoff';

export interface backendMessageResponse {
  message: string;
  status: string;
  error: string;
}

@Injectable({
  providedIn: 'root',
})
export class BackendService {
  public defaultHeaders = new HttpHeaders();

  constructor(
    private httpClient: HttpClient
  ) {}

  callBackend(path: string): Observable<any> {
    let headers = this.defaultHeaders;
    headers = headers.set('Accept', 'application/json');

    // cookies will not be set unless withCredentials is set to true
    return this.httpClient.get(environment.serverUrl + path, { withCredentials: true });
  }

  callBackendWithRetry(path: string): Observable<any> {
    return this.callBackend(path).pipe(
      retryWithBackoff(),
      catchError(error => {
        console.log(error);
        return(of( {message: null, status: error.status, error: error.message} ));
      }),
      map((body: any) => body),
    )
  }

  callProtectedRoute(): Observable<backendMessageResponse> {
    return this.callBackendWithRetry('protected_route');
  }

  callUserProtectedRoute(): Observable<backendMessageResponse> {
    return this.callBackendWithRetry('protected_route_user');
  }

  getUserProjectDetails(): Observable<backendMessageResponse> {
    return this.callBackendWithRetry('get_user_project_details');
  }

  getSampleFiles(projectId: string, sampleId:string): Observable<backendMessageResponse> {
    return this.callBackendWithRetry(`get_sample_files/${projectId}/${sampleId}`);
  }

  getProjectViewData(projectId: string): Observable<backendMessageResponse> {
    return this.callBackendWithRetry(`get_project_view_data/${projectId}`);
  }

  getProjectGeneData(projectId: string): Observable<backendMessageResponse> {
    return this.callBackendWithRetry(`get_project_gene_data/${projectId}`);
  }

  getS3Link(path: string): Observable<backendMessageResponse> {
    return this.callBackendWithRetry(`get_s3_link/${path}`);
  }

  refreshData(): Observable<backendMessageResponse> {
    return this.callBackendWithRetry('refresh_data');
  }
}
