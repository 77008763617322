<div class="container" style="padding-left: 15px; padding-right: 15px; padding-top: 25px">
  <app-loader [isLoading]="isLoading"></app-loader>
  <div *ngIf="projectDetails.length">  
    <div *ngIf="projectDetails.length > 1">
      <div class="row p-3">
        <div class="col col-lg-3">
          <h3>Project</h3>
          <select class="form-select" (change)="getSelectedProject($event)">
            <option *ngFor="let project of projectDetails" [value]="project.project_id">
        
              {{project.project_data['project_name']}}

            </option>
          </select>
        </div>
      </div>
    </div>

    <div *ngIf="selectedProject">
      <dl class="row p-3">
        <dt class="col-sm-2">Project ID:</dt>
        <dd class="col-sm-9">{{selectedProjectDetails.project_data['project_id']}}</dd>
        <dt class="col-sm-2">Project Name:</dt>
        <dd class="col-sm-9">{{selectedProjectDetails.project_data['project_name']}}</dd>
        <dt class="col-sm-2">Project Description:</dt>
        <dd class="col-sm-9">{{selectedProjectDetails.project_data['project_description']}}</dd>
      </dl>
    </div>

    <div *ngIf="projectFiles.length" class="row p-3">
      <h3>Project Files</h3>
    <div class="row">
      <ul class="list-inline">
        <div *ngFor="let file of projectFiles">
          <li class="list-inline-item">
            <a href={{file.file_red}}> <fa-icon [icon]="file.file_icon"></fa-icon></a>
            <button (click)="copyLinkToClipboard(file.file_url)" class="btn btn-link" style="--bs-btn-padding-y: 0rem; --bs-btn-padding-x: 0rem;"> <fa-icon [icon]="faCopy" [border]="false"></fa-icon></button>
            {{file.file_name}}
          </li>
        </div>
      </ul>
    </div>
    </div>
  </div>
  <div class="row p-3">  
      <h3>Project Samples</h3>
        <table #dtTableElement datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover"></table>
  </div>
</div>

<ng-template #content let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Link copied</h4>
	</div>
	<div class="modal-body">
    The download link has been copied to to your clipboard. It is valid for 24 hours.
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">OK</button>
    </div>
    
</ng-template>
